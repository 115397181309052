import User from "@/helpers/LocalStorage/User";

const IS_PROD = window.location.host === "purina.cat.market";

export const setYMParams = async params => {
  const USER_LS = User.get();

  if (IS_PROD && "ym" in window) {
    if (USER_LS && "id" in USER_LS) {
      const userId = USER_LS.id;

      window.ym(90068453, "params", {
        Общие: {
          [window.location.host]: {
            UserID: userId,
            ...params
          }
        }
      });
      window.ym(90068453, "params", {
        UserIDs: {
          [`UserID-${userId}`]: { Компания: [window.location.host], ...params }
        }
      });
    } else {
      window.ym(90068453, "params", {
        Общие: {
          [window.location.host]: params
        }
      });
    }
  }
};
