export default {
  namespaced: true,
  state: {
    sellers: [],
    amount: [],
    weight: [],
    competitorBrands: [],
    animalBreed: [],
    periodGroup: "day",
    selectedPackageSizeStore: {
      DYNAMICS_OF_PRICES: "package",
      DYNAMICS_OF_PROMO_PRICE: "package",
      DYNAMICS_OF_DISCOUNTS: "kg",
      DYNAMICS_OF_DELTA_RRP: "kg"
    }
  },
  getters: {},
  mutations: {
    setAnimalBreed(state, animalBreed) {
        state.animalBreed = animalBreed;
    },
    setSellers(state, sellers) {
      state.sellers = sellers;
    },
    setAmount(state, amount) {
      state.amount = amount;
    },
    setWeight(state, weight) {
      state.weight = weight;
    },
    setPeriodGroup(state, periodGroup) {
      state.periodGroup = periodGroup;
    },
    setSelectedPackageSizeStore(state, params) {
      state.selectedPackageSizeStore[params.key] = params.size;
    },
    setCompetitors(state, competitors) {
        state.competitorBrands = competitors;
    }
  },
  actions: {}
};
