export default ({ get, post }) => ({
  // ============Price Analysis=============
  getCompetitorsBrands() {
    return get("/price-analysis/competitor-brands");
  },

  getAnimalBreed(params) {
      return get("/dashboard/animal-breed", { params });
  },

  getSellersList(params) {
    return get("/price-analysis/sellers", { params });
  },

  getBoxSizesList(params) {
    return get("/price-analysis/amounts", { params });
  },

  getBoxWeightList(params) {
    return get("/price-analysis/weight", { params });
  },

  getPriceAnalysisCharts() {
    return get("/price-analysis/charts");
  },

  getChartData(params) {
    return post("/price-analysis/chart", params);
  },

  getPriceAnalysisProducts(params) {
    return get("/price-analysis/product-list", { params });
  },

  getPriceAnalysisSKU(params) {
    return post("/price-analysis/sku", params);
  },

    getPriceAnalysisWidgetsData(params) {
      return post("/price-analysis/widget", params);
    }
});
