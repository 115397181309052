<template lang="pug">
	.checkbox.flex.items-center.px-12(
		:class="classNames"
		@click="change()"
		@mouseover="hover(true)"
		@mouseleave="hover(false)"
	)
		.checkbox__icon.flex.justify-around.items-center(:style="{ minWidth: '24px' }")
			CheckIcon.checkbox__icon-check(:size="iconSize" :fillColor="checkboxColor")
		.checkbox__label(:class="{accent: isParentCategory}")
			slot
				| {{label}}

</template>

<script>
import CheckIcon from 'vue-material-design-icons/Check.vue'

export default {
	name: 'Checkbox',
	components: { CheckIcon },
	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
		label: {
			type: String,
		},
		value: [String, Boolean],
		type: {
			default: 'violet',
			validator: value => ~[
				'violet', 'gray',
			].indexOf(value),
		},
		size: {
			default: 'md',
			validator: value => ~['sm', 'md'].indexOf(value),
		},
		id: {
			type: [String, Number],
			default: 0,
		},
		subcats: {
			type: Array,
			default: () => [],
		},
	},
	data: ()=>{
		return {
			isHover: false
		}
	},
	computed: {
		classNames() {
			let cn = [
				this.value ? '--active checked' : '',
				this.disabled ? '--disabled' : '',
				`--size-${this.size}`,
				`--type-${this.type}`,
			]

			return cn
		},
		checkboxColor() {
			return '#5744D6'
		},
		iconSize() {
			return this.size === 'md' ? 18 : 16
		},
		isParentCategory() {
			return this.subcats.length
		},
	},
	methods: {
		hover(data) {
			this.isHover = data
		},
		change() {
			if (this.disabled) {
				return false
			}
			this.$emit('change', this)
		},
	}
}
</script>

<style lang="scss" scoped>
svg {
	background-color: red;
	fill: color(violet) !important;
}
.checked {
	.checkbox__icon {
		border: 2px solid color(violet) !important;
	}
}
.checkbox {
	cursor: pointer;

	&:hover:not(.--disabled) {
		.checkbox__icon {
			border: 2px solid color(violet);
		}
		color: color(violet);
	}
	&__icon {
		width: 24px;
		height: 24px;
		border-radius: 4px;

		&-check {
			display: none;
		}
	}
	&__label {
		margin-left: 8px;
	}
	&.--type {
		&-violet {
			.checkbox__icon {
				border: 2px solid color(gray-500);
			}

			&.--active {
				.checkbox__icon {
					border-color: color(violet);

					&-check {
						display: block;
					}
				}
			}
		}
		&-gray {
			.checkbox__icon {
				border: 2px solid color(gray-500);
			}
			&.--active {
				.checkbox__icon {
					&-check {
						display: block;
					}
				}
			}
		}
	}

	&.--size {
		&-sm {
			.checkbox {
				&__icon {
					width: 24px;
					height: 24px;
				}
			}
		}
		&-md {

		}
	}

	&.--disabled {
		opacity: 0.5;
		cursor: default;
	}
	::v-deep.material-design-icon__svg {
		color: color(violet) !important;
	}
}
.accent {
	font-weight: bold;
}
</style>
